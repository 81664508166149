import React, {useState} from "react";
import BannerImg from '../../assets/images/banner.png'


function Bookingconf() {
    //const navigate = useNavigate();
    return(
       

        <div className="flex sm:flex-row flex-col items-center sm:gap-40 gap-10">
                    
        <div className="flex flex-col items-center w-full">  {/* Container for image and its direct description */}
            <img src={BannerImg} alt="Descriptive Alt Text" />
            <p className="text-center text-sm mt-2">Dr. P. Narasimha Reddy MD, DM</p>
            <p className="text-center text-sm mt-2">Gasteroenterologist</p>  {/* Descriptive text specific to the image */}
        </div>

        <div className="flex flex-col w-full">  {/* Texts are in their own flex column container */}
                <p className="text-center text-lg mt-2"> An SMS confimration has been sent to your Mobile. Please arrive 10 minutes early for your scheduled appointment.</p>
                <p className="text-center text-lg mt-2"> Note that Online bookings cost 1000 Rs, which you will will be required to pay at reception.</p>
                <p className="text-center text-lg mt-2"> మీ మొబైల్‌కి SMS నిర్ధారణ పంపబడింది. దయచేసి మీ షెడ్యూల్ చేసిన అపాయింట్‌మెంట్ కోసం 10 నిమిషాల ముందుగా చేరుకోండి.</p>
                <p className="text-center text-lg mt-2"> ఆన్‌లైన్ బుకింగ్‌లకు రూ. 1000 ఖర్చవుతుందని గుర్తుంచుకోండి, మీరు రిసెప్షన్‌లో చెల్లించాల్సి ఉంటుంది.</p>
                <p className="text-center text-lg mt-2"> </p>
                <p className="text-center text-lg mt-2"> </p>
                <p className="text-center text-lg mt-2"> Address/చిరునామా : </p>
                <p className="text-center text-lg mt-2">2H39+M69, Vijaya Theater Road, Opposite Bus Depot,</p>
                <p className="text-center text-lg mt-2"> Kakaji Colony, Kakaji Nagar Colony, </p>
                <p className="text-center text-lg mt-2">Hanamkonda, Telangana 506001</p>
                <p className="text-center text-lg mt-2">Phone: 0870 244 8368</p>
            </div>
        

        </div>
    )
}

export default Bookingconf;