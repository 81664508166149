import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const isAuthenticated = JSON.parse(localStorage.getItem('authToken')); // Assume 'authToken' is stored during login
  const now = new Date();
  if (!isAuthenticated || !isAuthenticated.PositiveBool || !isAuthenticated.expirationTime || isAuthenticated.expirationTime < now.getTime()) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" replace />;
  }
  

  return children; // Render children (the protected component) if authenticated
}

export default ProtectedRoute