import React from "react";
import {useNavigate} from "react-router-dom";

function TimeSlot({time, select, date, setSelect}) {
    const navigate = useNavigate();
    function handleClick () {
        navigate('/schedule', {state: {date: date, time: time}})
    }
    return(
        <div className="flex gap-1">
            <div className={`${select === time?"w-[70px] text-white bg-[#004346]":"w-[140px] text-[#004346]"} border-[#004346]  py-1 border-[1px] cursor-pointer rounded-[5px] text-center transition-all duration-300`}  onClick={()=> setSelect(time)}>
                {time}
            </div>
            {select === time && (<div className="rounded-[5px] py-1 text-white bg-[#004346] px-4 cursor-pointer" onClick={handleClick}>
                Next
            </div>)}
        </div>
        
    )
}

export default TimeSlot;