import React from "react";

function TextArea({label, onChange, placeholder}) {
    return (
        <div className="flex flex-col gap-2">
            <span className="text-[#004346]">{label}</span>
            <textarea placeholder={placeholder} className="outline-none border-[1px] rounded-[5px] border-[#004346] px-3 py-2 text-[#004346] h-[200px]" onChange={(e) => {onChange(e.target.value)}}/>
        </div>
    )
}

export default TextArea