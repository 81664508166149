import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import Button from "../../components/button/Button";
import axios from 'axios';

function Login() {
    const [password, setPassword] = useState("")
    const [wrong, setWrong] = useState(false)
    const navigate = useNavigate();
    const Bool = true;
    const now = new Date();
    const expirationTime = now.getTime() + 3600000;
    const PositiveBool = true;

    const handleLogin = async () => {
        // Example POST request to server for authentication
        try {
            
            
            const response = await axios.post('https://pnrgutnliver.com/api/login', {
                password: password
            });
            console.log("Here1")
            console.log(response.data.message)
            console.log(response)
            if (response.status == 200) {
                //const { authToken } = await response.json();
                // Assuming the server returns a secure, HttpOnly cookie for the session
                // set local storage item
                console.log("Here2")
                localStorage.setItem('authToken', JSON.stringify({ PositiveBool, expirationTime }));
                console.log(response.data.message)
                localStorage.setItem('isKey', JSON.stringify({key: response.data }));
                navigate('/admin');
            } else {
                setWrong(true);
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };
    
    
    return(
        <div className="flex flex-col items-center gap-4 border-2 p-10 rounded-2xl">
            <span className="text-4xl font-normal">Login</span>
            <div className="flex flex-col">
                <input value={password} type="password" placeholder="Enter your password here"
                    className="sm:w-[400px] :w-[100px] h-[48px] rounded-[8px] border-[1px] pl-[8px] outline-none"
                    onChange={(e) => setPassword(e.target.value)} 
                />
                <span className={`${wrong ? "" : "hidden"} text-red-600`}>password is wrong</span>
            </div>
            <Button name="login" onClick={handleLogin}/>
        </div>
    )
}

export default Login;