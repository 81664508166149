import React from "react";

function Header() {
    return(
        <div className=" flex flex-col items-center justify-center h-[100px] bg-[#182B3B] border-black border-b-2 px-5">
            <div className=" text-white text-4xl font-normal cursor-pointer" onClick={()=>{window.location.href = "/"}}>
                PNR GUT AND LIVER CLINIC
            </div>
            <div className="text-white text-xl mt-2 font-normal cursor-pointer">
                Gastroenterologist
            </div>
        </div>
    )
}

export default Header;