import React, {useEffect, useState} from "react";
import Calendar from 'react-calendar';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';

import Button from "../../components/button/Button";
import './admin.css'

function Admin() {
    const [bookings, setBookings] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        (async() => {
            const res = await axios.get('https://pnrgutnliver.com/api/get_booking');
            setBookings(res.data)
            const response = await axios.get('https://pnrgutnliver.com/api/get_schedule');
            const temp = [];
            response.data.forEach((item)=> {
                temp.push(new Date(item.date).toLocaleDateString())
            })
            setSchedules(temp)
        })()
        // Initialization or one-time setup actions here
      }, []);  

    useEffect(() => {
        (async() => {
            //const res = await axios.get('http://localhost:5000/api/get_booking');
            //setBookings(res.data)
            const response = await axios.get('https://pnrgutnliver.com/api/get_schedule');
            const temp = [];
            response.data.forEach((item)=> {
                temp.push(new Date(item.date).toLocaleDateString())
            })
            setSchedules(temp)
        })()
    },[schedules]);

    async function fetchBookingsForDate(selectedDate) {
        const formattedDate = selectedDate.toISOString().split('T')[0]; // Format the date as required by your backend
        //const url = `http://localhost:5000/api/get_bookingsspecific?date=${formattedDate}`;
        //console.log(url);  // Check what URL is being requested
        const res = await axios.post('https://pnrgutnliver.com/api/bookingsspecific',{
            date: formattedDate
        });
        setBookings(res.data); // Update the bookings state with the new data
    };

    async function cancelBooking(id) {
        const isKey = JSON.parse(localStorage.getItem('isKey'))
        const res = await axios.post('https://pnrgutnliver.com/api/delete_booking', {
            id: id,
            key: isKey.key
            
        });
        if (res.status === 200) {
            console.log(res.data)
        }
    }

    async function cancelSchedule() {
        const isKey = JSON.parse(localStorage.getItem('isKey'))
        console.log("Entered Here")
        console.log(date)
        const localDate = new Date(date);
        console.log("localDate")
        console.log(localDate.toISOString())
        const utcDate = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate());

        // Perform the deletion for the whole UTC day
        const startOfUtcDay = new Date(utcDate);
        
        startOfUtcDay.setUTCHours(14, 0, 0, 0); // Start of day in UTC
        console.log(startOfUtcDay.toISOString())
    
        

        const res = await axios.post('https://pnrgutnliver.com/api/delete_schedule', {
            date: localDate.toISOString(),
            key: isKey.key
           
        });
        if (res.status === 200) {
            console.log(res.data)
            toast.success("Successfully disabled")
            
        }
    }

    async function makeSchedule() {
        // Check if the current date is in your list of scheduled dates, then disable and remove from scheduled dates
        const isKey = JSON.parse(localStorage.getItem('isKey'))
        if (schedules.includes(date.toLocaleDateString())){
            // remove from schedules
            console.log(date)
            cancelSchedule()
        }else{ // If not in the list of scheduled dates, then enable
            const res = await axios.post('https://pnrgutnliver.com/api/make_schedule', {
            date: date,
            key: isKey.key
            })

            if(res.data === 'schedule_success') {
                toast.success("Successfully enabled")
            }
            if(res.data === 'repeate') {
                toast.warning("already scheduled")
            }
            
        }
         
        
        
    }

    return(
        <div className="flex flex-col sm:flex-row gap-8 sm:w-auto w-[300px]">
            <div className="flex flex-col gap-2">
            <Calendar onChange={(e)=> {setDate(e); fetchBookingsForDate(e);}} value={date}
                tileClassName={({ date }) => {
                // Check if the current date is in your list of scheduled dates
                if (schedules.includes(date.toLocaleDateString())) {
                    // Apply a specific class for scheduled dates
                    return 'scheduled-date';
                }
                // If the date is not in the scheduled dates list, return an empty string
                return '';
                }}
            />
                <Button name="enable/disable" onClick={()=> makeSchedule()}/>
            </div>
            <div className="overflow-x-auto">
                <span>Bookings</span>
                <table>
                <thead>
                    <tr>
                        <th>date</th>
                        <th>time</th>
                        <th>content</th>
                        <th>name</th>
                        <th>phone</th>
                        <th>operations</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        bookings.map((item) => (
                            <tr key={item._id}>
                                <td>{new Date(item.date).toLocaleDateString("it-IT")}</td>
                                <td>{item.time}</td>
                                <td>{item.text}</td>
                                <td>{item.name}</td>
                                <td>{item.phone}</td>
                                <td>
                                <Button name="cancel" onClick={() => {
                                    if (window.confirm("Are you sure you want to cancel this booking?")) {
                                        cancelBooking(item._id);
                                        window.location.reload();
                                    }
                                    }}/>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
                </table>
            </div>
        </div>

    )
}

export default Admin;