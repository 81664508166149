import React, {useState} from "react";
import {toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {useLocation,useNavigate} from "react-router-dom" ;
import Input from "../../components/input/Input";
import TextArea from "../../components/text-area/TextArea";
import Button from "../../components/button/Button";
import ArrowRight from "../../assets/svg/arrow-right.svg";

import axios from 'axios';

function Schedule() {
    const location = useLocation();
    const navigation = useNavigate();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [text, setText] = useState("");
    const [name_validate, setNameValidate] = useState(true);
    const [phone_validate, setPhoneValidate] = useState(true);

    async function handleClick () {
        const booking = {
            date: location.state.date,
            time: location.state.time,
            name: name,
            phone: phone,
            text: text
        }

        const isValidPhone = phone.replace(/\D/g, '').length === 10;
        if (!name_validate && !phone_validate && isValidPhone) {
            try {
                const res = await axios.post("https://pnrgutnliver.com/api/create_booking", booking);
                if(res.status === 200) {
                    toast('Successfully booked, Please not that online bookings cost more (1000 Rs). Please arrive 10 minutes early for your appointment and make sure to show the SMS confirmation sent to your mobile.', {autoClose: 30000})
                    localStorage.removeItem('bookingConfirmed');
                    navigation('/bookingConfirmed')
                    //setTimeout(() => {
                    //    navigation('/');
                    //}, 25000)
                    
                }
                if(res.status === 204) {
                    toast.warning('There is already a booking for this phone number, you cannot make another one. ఈ ఫోన్ నంబర్ కోసం ఇప్పటికే బుకింగ్ ఉంది, మీరు మరొకదాన్ని చేయలేరు.')
                }
            } catch (error) {
                if(error.response.status === 400) {
                    toast.warning('Cant send SMS to this phone number')
                }
                if(error.response.status === 500) {
                    toast.error('Server error')
                }
            }
        }else{
            toast.warning('Cant send SMS to this phone number. The number should be Indian and 10 digits. ఈ ఫోన్ నంబర్‌కి సందేశం పంపడం సాధ్యం కాదు. సంఖ్య భారతీయ మరియు 10 అంకెలు అయి ఉండాలి')
        }
    }
    return(
        <div className="flex flex-col gap-10 w-[400px] mx-4 my-2">
            <img src={ArrowRight} alt="back" width={20} className="border-[1px] rounded-[100%] border-[#004346] rotate-180 cursor-pointer" onClick={()=> {navigation("/")}}/>
            <Input label="Name" onChange={setName} validate={name_validate} setValidate={setNameValidate}/>
            <Input label="Phone number" onChange={setPhone} validate={phone_validate} setValidate={setPhoneValidate}/>
            <p className="text-center text-lg mt-2">Please note that online bookings cost 1000 Rs / ఆన్‌లైన్ బుకింగ్‌ల ధర 1000 రూపాయలు అని గమనించండి </p>
            <Button name="Schedule event" onClick={handleClick}/>
            <ToastContainer/>
        </div>
    )
}

export default Schedule