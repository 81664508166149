import React from "react";
import {useNavigate} from 'react-router-dom'
import Button from "../../components/button/Button";

import BannerImg from '../../assets/images/banner.png'
import TextArea from "../../components/text-area/TextArea";

function Home() {
    const navigate = useNavigate();
    return(
        <div className="flex sm:flex-row flex-col items-center sm:gap-40 gap-10">
            
            <div className="flex flex-col items-center w-full">  {/* Container for image and its direct description */}
                <img src={BannerImg} alt="Descriptive Alt Text" />
                <p className="text-center text-sm mt-2">Dr. P. Narasimha Reddy MD, DM</p>
                <p className="text-center text-sm mt-2">Gastroenterologist</p>  {/* Descriptive text specific to the image */}
            </div>
           
            <div className="flex flex-col w-full">  {/* Texts are in their own flex column container */}
                <p className="text-center text-lg mt-2">Online Bookings are only available for the 2:30 PM (14:30) time slot.</p>
                <p className="text-center text-lg mt-2">For all other bookings, please go to the clinic (Address and Contact details below).</p>
                <p className="text-center text-lg mt-2">ఆన్‌లైన్ బుకింగ్‌లు 2:30 PM (14:30) టైమ్ స్లాట్‌కు మాత్రమే అందుబాటులో ఉంటాయి.</p>
                <p className="text-center text-lg mt-2">అన్ని ఇతర బుకింగ్‌ల కోసం, దయచేసి క్లినిక్‌కి వెళ్లండి (క్రింద చిరునామా మరియు సంప్రదింపు వివరాలు).</p>
                <p className="text-center text-lg mt-2"> </p>
                <p className="text-center text-lg mt-2"> </p>
                <p className="text-center text-lg mt-2"> Address/చిరునామా : </p>
                <p className="text-center text-lg mt-2"> 6-2-42, Vijaya Theater Road</p>
                <p className="text-center text-lg mt-2"> Kakaji Colony </p>
                <p className="text-center text-lg mt-2">Hanamkonda, Telangana 506001</p>
                <p className="text-center text-lg mt-2">Phone: 9908760690, 9603760690</p>
                <p className="text-center text-lg mt-2"> </p>
                <p className="text-center text-lg mt-2"> Clinic Timings: 8 am to 4 pm, Monday to Saturday / క్లినిక్ సమయాలు: ఉదయం 8 నుండి సాయంత్రం 4 వరకు, సోమవారం నుండి శనివారం వరకు</p>
                <p className="text-center text-lg mt-2"> Phone and walk in bookings start at 7 am / ఫోన్ మరియు క్లినిక్ ఆఫీసు బుకింగ్‌లు ఉదయం 7 గంటలకు ప్రారంభమవుతాయి </p>


            </div>
            <Button name="Book Now" className="m-2 sm:m-4 lg:m-8" onClick={()=>navigate("/booking")}/>
            
        </div>
    )
}

export default Home;