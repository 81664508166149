import React from "react";
import Header from "./header";
import Footer from "./footer";

function Layout({children}) {
    return(
        <div className=" flex flex-col min-h-[100vh]">
            <Header/>
                {children}
            <Footer/>
        </div>
    )
}

export default Layout;