import { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import TimeSlot from '../../components/time-slot/TimeSlot';
import axios from 'axios'

import 'react-calendar/dist/Calendar.css';

function Booking() {
  const [date, setDate] = useState(new Date())
  const [date1, setDate1] = useState()
  const [select, setSelect] = useState("")
  const [schedules, setSchedules] = useState([])

  //const TIMES = ["08:00", "09:00", "10:30", "11:30", "12:10", "13:00", "13:40", "14:30", "15:00", "17:00"]
  const TIMES = ["15:00"]

    useEffect(() => {
        (async() => {
            const temp=[]
            
            
            const res = await axios.get('https://pnrgutnliver.com/api/get_schedule');
            for (const item of res.data) {
                const res1 = await axios.post('https://pnrgutnliver.com/api/bookingsspecific', {
                    date: item.date
                });
                // Check how many results are in res1, if fewer than 10, add it to temp for display
                if (res1.data.length < 6) {
                    temp.push(new Date(item.date).toLocaleDateString());
                }
            }
            setSchedules(temp);


            //res.data.forEach((item)=> {
                // Check number of bookings for the time slot here 
                // checking for the day is enough for now , future it has to be checked for the timeslot , or set only one booking per timeslot
                //const res1 = await axios.post('http://localhost:5000/api/bookingsspecific',{
                //date: item.date
                //});
                //console.log(item)
                //Check how many results are in res1, if greater than one, do not add it to the  temp for display
                //if (res1.data.length < 10){
                //    temp.push(new Date(item.date).toLocaleDateString())
                //}
                
            //})
            //setSchedules(temp)
        })()
    },[]); // This used to be [schedules] at the end, but did not want an infinite loop, but [schedules] does monitor it each time

    function handleChange(e) {
        setDate(e);
        setSelect("");
        setDate1(true);
        localStorage.setItem('bookingConfirmed', JSON.stringify({ date: e, confirmed: true }));
    
    }

  return (
    <div className='flex flex-col'>
        <div>
            Select a Date and Time
        </div>
        <div className='flex flex-col sm:flex-row gap-5'>
            <Calendar onChange={(e)=> {handleChange(e)}} value={date} tileDisabled={({ date, view }) =>
            (view === "month" && schedules.indexOf(date.toLocaleDateString()) === -1)
            }
        />
        {date1 && (
            <div className='sm:flex sm:flex-col grid grid-cols-2 gap-2 mb-2'>
                {
                    TIMES.map((time) => (
                        <TimeSlot key={time} time={time} select={select} setSelect={setSelect} date={date} />
                    ))
                }
            </div>
        )}
        </div>
    </div>
    
    
  );
}

export default Booking;