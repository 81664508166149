import React from "react";

function Input ({label, onChange, validate,setValidate}) {
    return(
        <div className="flex flex-col gap-2">
            <span className="text-[#004346]">{label}</span>
            <input type="text" className="outline-none border-[1px] rounded-[5px] border-[#004346] px-3 py-2 text-[#004346]" 
                onChange={(e)=>{
                    onChange(e.target.value);
                    if (e.target.value !== "") setValidate(false);
                    else setValidate(true)
                }}
            />
            <span className={`${validate? '' : 'hidden' } text-red-600`}>*this is required</span>
        </div>
    )
}

export default Input;