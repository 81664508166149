import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import BookingProtectedRoute from './components/BookingProtectedRoute/BookingProtectedRoute';
import Layout from './components/layout/layout';
import Home from './pages/home/home';
import Booking from './pages/booking/Booking';
import Schedule from './pages/schedule/Schedule';
import Admin from './pages/admin/admin';
import Bookingconf from './pages/bookingconf/bookingconf';

import './App.css';
import Login from './pages/login/login';

function App() {
  return (
    <Layout>
      <div className='flex justify-center my-auto'>
          <BrowserRouter>
              <Routes>
                  <Route path='/login' element={<Login/>}/>
                  <Route path='/' element={<Home/>}/>
                  <Route path='/admin' element={
                    <ProtectedRoute>
                         <Admin />
                    </ProtectedRoute>
                     

                    } />
                  <Route path='/booking' element={<Booking/>}/>
                  <Route path='/schedule' element={
                    <BookingProtectedRoute>
                      <Schedule />
                    </BookingProtectedRoute>
                  } />
                  <Route path='/bookingconfirmed' element={<Bookingconf/>}/>
              </Routes>
          </BrowserRouter>
      </div>
      <ToastContainer/>
    </Layout>
  );
}

export default App;
